$(function () {
    //Find all placeholders
    setTimeout(function () {
        var placeholders = document.querySelectorAll("input[placeholder],textarea[placeholder]");

        if (placeholders.length) {
            // convert to array
            placeholders = Array.prototype.slice.call(placeholders);

            // Remove all falsy values: undefined, null, 0, false, NaN and "" (empty string)
            function cleanArray(actual) {
                var newArray = new Array();
                for (var i = 0; i < actual.length; i++) {
                    if (actual[i].placeholder) {
                        newArray.push(actual[i]);
                    }
                }
                return newArray;
            }

            var placeholderClean = cleanArray(placeholders);


            if (placeholderClean.length) {

                // copy placeholder text to a hidden div
                var div = $('<div id="placeholders" style="display:none;"></div>');

                placeholderClean.forEach(function (input) {
                    var text = input.placeholder;
                    div.append("<div>" + text + "</div>");
                });

                $("body").append(div);

                // save the first placeholder in a closure
                var originalPh = placeholderClean[0].placeholder;

                // hoisted ---------------------------
                function isTranslated() { // true if the text has been translated
                    var currentPh = $($("#placeholders > div")[0]).text();
                    return originalPh !== currentPh;
                }

                function updatePlaceholders() {
                    $("#placeholders > div").each(function (i, div) {
                        placeholderClean[i].placeholder = $(div).text();
                    });
                    //blur all placeholders to make sure their text is updated. This is for Select2 input fields.
                    $.each(placeholders, function (ele) {
                        $(ele).blur();
                    });
                }
                // check for changes and update as needed
                setInterval(function () {
                    if (isTranslated()) {
                        updatePlaceholders();
                        originalPh = placeholderClean[0].placeholder;
                    }
                }, 500);

            }


        }
    }, 500);

});
